<template>
  <div class="create-faq">
    <FaqAppbar />
    <div class="ma-4">
      <FaqForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Hub FAQ
 * ==================================================================================
 **/

import FaqAppbar from '@/views/Home/Hub/Faqs/components/FaqAppbar'
import FaqForm from '@/views/Home/Hub/Faqs/components/FaqForm'

export default {
  components: {
    FaqAppbar,
    FaqForm,
  },
}
</script>
<style lang="scss">
.create-faq {
  //
}
</style>
